import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from '@/plugins/router'
import store from '@/plugins/store'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import '@/assets/css/core.scss'
import '@/plugins/rem.js'
import 'font-awesome/css/font-awesome.min.css'
import moment from 'moment'
import fastclick from 'fastclick'
import axios from '@/plugins/axios'
import {apiUrl, imgUrl, hlsUrl} from '@/plugins/constants.js'
import sec from "@/plugins/user";
Vue.prototype.apiUrl = apiUrl
Vue.prototype.imgUrl = imgUrl
Vue.prototype.hlsUrl = hlsUrl

fastclick.attach(document.body)
Vue.prototype.$http = axios

Vue.use(MintUI)
Vue.use(Vuex)
Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.prototype.$sec = sec

axios.get('/home/cfg').then(resp=>{
    store.commit('setEnvs', resp.data)
    document.title=resp.data.projectCnName //修改title
})

const vm = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
export default vm;

