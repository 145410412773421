const origin = location.origin
const pathName = location.pathname
let apiUrlContext =''
if(!pathName.startsWith("/eqms-ui/index.html")){
    apiUrlContext = pathName.substring(0,pathName.indexOf("/eqms-ui"));
}
const apiUrl = origin.indexOf('localhost') > 0 /*|| location.port === '8080'*/? `http://${location.hostname}:8088${apiUrlContext}` : origin+apiUrlContext
const imgUrl = `${apiUrl}/fs/z2`
const hlsUrl = `${apiUrl}/hls`
export {apiUrl, imgUrl, hlsUrl}
