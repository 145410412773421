// import store from "@/plugins/store";
// import {apiUrl} from "@/plugins/constants";
import axios from '@/plugins/axios'
import {apiUrl} from "@/plugins/constants";
function contains(arr, obj) {
    let i = arr.length;
    while (i--) {
        if (arr[i] === obj) {
            return true;
        }
    }
    return false;
}

const initUserInfo = function (callback) {
    //获取链接携带的参数token
    let token = window.location.href.split('token=')[1]
    //判断token是否存在
    let da=''
    if (token) {
        da="?token="+token
    }

    axios.post("/api/account/user"+da).then(result => {

        localStorage.setItem('user', JSON.stringify(result))
        localStorage.setItem('timeOut',new Date().getTime())
        callback()
    })
}

const getUser = function () {
    let timeout=localStorage.getItem('timeOut');
    let newtime= new Date().getTime();
    let timediff=newtime-timeout;
    if(timediff>3600000){
        removeUser()
        return null;
        // console.log("超时")
        // console.log("url:",apiUrl+'/portal/logout')
        // axios.post('/portal/logout').then(res=>{
        //     return null
        // })

    }else {
        let userStr = localStorage.getItem('user')
        return userStr ? JSON.parse(userStr) : null
    }

}

const isLogin = function () {
    let user = getUser()
    console.log("user",user)
    return user && user.id > 1
}

const removeUser = function () {
    localStorage.removeItem('user')
    localStorage.removeItem('timeOut')
}

const hasAnyRole = function () {
    const user = getUser()
    if (user) {
        for (const role of arguments) {
            if (contains(user.roles, role)) {
                return true
            }
        }
    }
    return false
}

const sec = {
    initUserInfo, hasAnyRole, removeUser, getUser, isLogin
}

export default sec

