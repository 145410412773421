import Vue from 'vue'
import VueRouter from "vue-router"

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

import sec from '@/plugins/user'
import axios from '@/plugins/axios'


const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/index'
        }, {
            path: '/index',
            component: () => import('../pages/index.vue'),
        },


        {
            path: '/booking',
            component: () => import('../pages/booking.vue'),
        },
        {
            path: '/booking-cal',
            component: () => import('../pages/booking-cal.vue'),
        },
        {
            path: '/booking-plan',
            component: () => import('../pages/booking-plan.vue'),
        },
        {
            path: '/bookings',
            component: () => import('../pages/bookings.vue'),
        },
        {
            path: '/license',
            component: () => import('../pages/license.vue'),
        },
        {
            path: '/events',
            component: () => import('../pages/events.vue'),
        },
        {
            path: '/states',
            component: () => import('../pages/states.vue'),
        },
        {
            path: '/authorities',
            component: () => import('../pages/authorities.vue'),
        },
        {
            path: '/bill',
            component: () => import('../pages/bill.vue'),
        },
        {
            path: '/monitor',
            component: () => import('../pages/monitor.vue'),
        },
        {
            path: '/booking-mng',
            component: () => import('../pages/booking-mng.vue'),
        },
        {
            path: '/login',
            component: () => import('../pages/login.vue'),
        },
        {
            path: '/logout',
            component: () => import('../pages/logout.vue'),
        },
        {
            path: '/scan',
            component: () => import('../pages/scan.vue'),
        },
        {
            path: '/confirm',
            component: () => import('../pages/confirm.vue'),
        },
        {
            path: '/exp',
            component: () => import('../pages/exp.vue'),
        },
        {
            path: '/expInfo',
            component: () => import('../pages/exp-info.vue'),
        },
        {
            path: '/company-mng',
            component: () => import('../pages/company-mng.vue'),
        },
        {
            path: '/license-mng',
            component: () => import('../pages/license-mng.vue'),
        },
        {
            path: '/subject-mng',
            component: () => import('../pages/subject-mng.vue'),
        },
        {
            path: '/samples',
            component: () => import('../pages/samples.vue'),
        },
        {
            path: '/exp-score',
            component: () => import('../pages/exp-score.vue'),
        },
        {
            path: '/exp-mng',
            component: () => import('../pages/exp-mng.vue'),
        },
        {
            path: '/point-mng',
            component: () => import('../pages/point-mng.vue'),
        },
        {
            path: '/finance-mng',
            component: () => import('../pages/finance-mng.vue'),
        },
        {
            path: '/eq-maintain',
            component: () => import('../pages/equipment-maintain.vue'),
        },
        {
            path: '/event-feedback',
            component: () => import('../pages/event-feedback.vue'),
        },
        {
            path: '/eq-violatrecord',
            component: () => import('../pages/equipment-violatrecord.vue'),
        },
        {
            path: '/addmainTain',
            name: 'addmainTain',
            component: () => import('../pages/equipment-maintain-add.vue'),
        },
        {
            path: '/addEventFeedback',
            name: 'addEventFeedback',
            component: () => import('../pages/event-feedback-add.vue'),
        },
        {
            path: '/addviolatrecord',
            name: 'addviolatrecord',
            component: () => import('../pages/equipment-violatrecord-add.vue'),
        },
        {
            path: '/wxScan',
            component: () => import('../pages/wxScan.vue'),
        },
        {
            path: '/forgot',
            component: () => import('../pages/forgot.vue'),
        },
        {
            path: '/forgot-email',
            component: () => import('../pages/forgot-email.vue'),
        }



    ]
});

if (window.top === window.self) {
    router.beforeEach((to, from, next) => {

        if (sec.isLogin()) { // 判断是否登录
            if (to.path === '/login') {  //
                //登录状态下 访问login.vue页面 会跳到index.vue
                next({path: '/index'})
            } else if(to.path === '/wxScan'){
                //扫码链接，先判断登陆状态再进行操作
                var eq= window.location.href
                var num= eq.indexOf("equipmentId")
                var  eqId= eq.slice(num+12,num+17)

                axios.post("/api/account/user").then(result => {
                    if(result.id > 1){
                        next()
                    }else {
                        // window.location.href="https://authserver.nju.edu.cn/authserver/login?service=http://sic.nju.edu.cn/#/wxScan?equipmentId="+eqId
                        const hostname = window.location.hostname;
                        var redirect= "http://"+hostname+"/#/wxScan?equipmentId="+eqId

                        sec.initUserInfo(function (){
                            window.location.href="https://authserver.nju.edu.cn/authserver/login?service="+redirect
                        })
                    }
                })
            }else {
                next()
            }
        } else {
            console.log("no_login")

            if (to.path === '/login' || to.path === '/logout' || to.path === '/forgot' ){
                next()
            } else if(to.path === '/forgot-email') {
                sec.initUserInfo(function (){
                    if (sec.isLogin()){
                        next({path: '/forgot-email', query: to.query})
                    }else {
                        next({path: '/login'})
                    }
                })

            }else {
                sec.initUserInfo(function (){
                next({path: '/login'})
            })
            }


        }
    });
}
export default router
