import store from "@/plugins/store";
import {apiUrl} from "@/plugins/constants";
import axios from '@/plugins/axios'

const init = function () {
    dictItems('equipment')
    dictItems('guard')
    dictItems('maintainType')
    dictItems('maintainer')
    dictItems('event_type')
    dictItems('violate_type')
    dictItems('violate_solve')
    dictItems('user')
    dictItems('dict_room')
}

const dictItems = function (key) {
    const writer = `set${key.slice(0, 1).toUpperCase() + key.slice(1)}s`
    const data = new FormData()
    data.append('key', key)
    data.append('queryAll', 'true')
    axios.post('/api/dict', data).then(resp=>{
        store.commit(writer, resp.results)
    })
}

const dict = {init}
export default dict