<template>
  <div class="app-header" v-if="!inIframe">
    {{ title }}
    <div class="header-left" @click="$router.back()"><i class="iconfont icon-prev"></i></div>
    <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
  </div>
</template>
<style lang="scss" scoped>
.app-header {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  //border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  //box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.header-left, .header-right {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

}

.header-left {
  left: 0;
}

.header-right {
  right: 0;
}

.icon-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.go-home {
  display: block;
  width: 21px;
  height: 19px;
  background: url("../assets/images/go-home.png");
  background-size: contain;
}

</style>
<script>
export default {
  name: "AppHeader",
  data() {
    return {
      inIframe: false
    }
  },
  mounted() {
    this.inIframe = window.top !== window.self
  },
  methods: {
    goHome() {
      this.$router.push("/")
    }
  },
  props: ["title"]
}
</script>