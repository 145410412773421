<template>
  <div id="app" :class="`page ${inIframe?'in-iframe':''}`">
    <div class="content-wrapper">
      <router-view class="content">
      </router-view>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page {
  width: 100%;
  justify-content: center;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  flex-direction: row;
  display: block;
  background: #F7F6F9;
  padding-top: 46px;

  &.in-iframe{
    background-image: none !important;
    background: #fff;
  }
}

.content-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
}

.content {
  width: 100%;
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import dict from "@/plugins/dict";

export default {
  name: 'App',
  components: {
    AppHeader
  },
  data() {
    return {
      inIframe: false
    }
  },
  mounted() {
    this.inIframe = window.top !== window.self
    dict.init()
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>
