import Vue from 'vue';
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        equipments: [],
        guards: [],
        curBooking: null,
        envs: {},
        mode: 'user',
        maintainTypes: [],
        maintainers: [],
        eventTypes: [],
        violateTypes: [],
        violateSolves: [],
        users: [],
        dictRooms: []
    },
    mutations: {
        setEquipments(state, equipments) {
            state.equipments = equipments
        },
        setGuards(state, guards) {
            state.guards = guards
        },
        setCurBooking(state, curBooking) {
            this.state.curBooking = curBooking
        },
        setEnvs(state, envs){
            this.state.envs = envs
        },
        setMode(state, mode){
            this.state.mode = mode
        },
        setMaintainTypes(state,maintainTypes){
            state.maintainTypes=maintainTypes
        },
        setMaintainers(state,maintainers){
            state.maintainers=maintainers
        },
        setEvent_types(state,eventTypes){
            state.eventTypes=eventTypes
        },
        setViolate_types(state,violateTypes){
            state.violateTypes=violateTypes
        },
        setViolate_solves(state,violateSolves){
            state.violateSolves=violateSolves
        },
        setUsers(state,users){
            state.users=users
        },
        setDict_rooms(state,dictRooms){
            state.dictRooms=dictRooms
        }
    },
    getters: {
        equipments: state => state.equipments,
        guards: state => state.guards,
        curBooking: state => state.curBooking,
        envs: state => state.envs,
        mode: state => state.mode,
        maintainTypes: state => state.maintainTypes,
        maintainers: state => state.maintainers,
        eventTypes: state => state.eventTypes,
        violateTypes: state => state.violateTypes,
        violateSolves: state => state.violateSolves,
        users: state => state.users,
        dictRooms: state => state.dictRooms
    },
    actions: {},
    modules: {}
})
